<template>
 <div>
  <svg height="183" viewBox="0 0 183 183" width="183" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
   <defs>
    <linearGradient gradientUnits="objectBoundingBox" id="linear-gradient" x1="0.798" x2="0.5" y2="1">
     <stop :stop-color="color1" offset="0"/>
     <stop :stop-color="color2" offset="1"/>
    </linearGradient>
   </defs>
   <g id="icon_textbox" transform="translate(-598 -2564)">
    <rect :fill="background" data-name="Rectangle 191" height="183" id="Rectangle_191" transform="translate(598 2564)" width="183"/>
    <g data-name="MDI / caps-lock" id="MDI_caps-lock" style="isolation: isolate" transform="translate(632.535 2599)">
     <g fill="url(#linear-gradient)" id="Boundary" opacity="0" stroke="rgba(0,0,0,0)" stroke-width="1">
      <rect :fill="background" height="113.929" stroke="none" width="113.929"/>
      <rect :fill="background" height="112.929" width="112.929" x="0.5" y="0.5"/>
     </g>
     <path d="M39.786,57.588,49.47,31.859l9.636,25.776M45.388,20.988,23.029,77.952h9.162l4.557-12.2H62.192l4.557,12.2h9.162L53.553,20.988H45.388M87.447,2H11.494A9.461,9.461,0,0,0,2,11.494V87.447a9.491,9.491,0,0,0,9.494,9.494H87.447a9.461,9.461,0,0,0,9.494-9.494V11.494A9.491,9.491,0,0,0,87.447,2m0,85.447H11.494V11.494H87.447Z" data-name="Path / caps-lock" fill="url(#linear-gradient)" id="Path_caps-lock" transform="translate(7.494 7.494)"/>
    </g>
   </g>
  </svg>
 </div>
</template>
<script>
export default {
  props: ["color1", "color2", "background"],
};
</script>